import React, { useEffect } from "react";
import { SignIn } from "@clerk/nextjs";
import Image from "next/image";

const SignInPage = () => {
  useEffect(() => {
    // This will run on the client side after the component is mounted
    localStorage.removeItem("authToken");
  }, []);

  return (
    <div className="grid h-screen md:place-items-center ">
      <SignIn
        path="/sign-in"
        routing="path"
        signUpUrl="/sign-up"
        afterSignInUrl="/onboarding"
        afterSignUpUrl="/onboarding"
      />
      {/* <Image
        className=""
        src="/GG_Under_Maintenance.png"
        alt="Guest Guru"
        height={600}
        width={600}
      /> */}
    </div>
  );
};

export default SignInPage;
